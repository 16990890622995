<template>
    <div class="row justify-content-center">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <h1 class="mt-4 font-weight-bolder"><i class="fas fa-edit mr-3"></i>Edit Podcast</h1>
            <hr>
            <div v-if="!isLoad" class="row">
                <div v-if="form.id != ''" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-5 p-auto">
                    <div class="card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 p-3">
                        <form @submit.prevent="patchMaterials" class="row p-2" style="z-index: 99999">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="row p-auto">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-md-6 mb-2">
                                                <div class="card" style="height: 25vh">
                                                    <div v-if="thumbs.length">
                                                        <div class="progress" style="height: 7px;">
                                                            <div class="progress-bar progress-bar-striped" :class="{'progress-bar-animated': thumbs[0].active, 'w-50 bg-current' : thumbs[0].active, 'w-100 bg-current': thumbs[0].success, 'w-100 bg-danger' : thumbs[0].error}" role="progressbar" :style="{width: thumbs[0].progress + '%'}"></div>
                                                        </div>
                                                    </div>
                                                    <div class="text-center py-5">
                                                        <ul v-if="thumbs.length">
                                                            <li>
                                                                <span>{{thumbs[0].name}} - {{thumbs[0].size | sizeFormat}}</span> -
                                                                <!-- <span>{{$formatSize(thumbs[0].size)}}</span> - -->
                                                                <span class="badge badge-danger text-capitalize" v-if="thumbs[0].error">{{thumbs[0].error}}</span>
                                                                <span class="badge badge-success" v-else-if="thumbs[0].success"><i class="fas fa-check"></i></span>
                                                                <div v-else-if="thumbs[0].active" class="spinner-border spinner-border-sm text-warning" role="status">
                                                                    <span class="sr-only">Loading...</span>
                                                                </div>
                                                                <span v-else></span>
                                                            </li>
                                                        </ul>
                                                        <h4 v-else>Input your Thumbnail !</h4>
                                                        <div class="m-auto">
                                                            <file-upload
                                                            input-id="thumb"
                                                            name="thumb"
                                                            class="btn btn-current mr-1"
                                                            @input="updateThumb"
                                                            @input-file="inputThumb"
                                                            @input-filter="thumbFilter"
                                                            :custom-action="thumbAction"
                                                            accept="image/jpg,image/png"
                                                            ref="thumb">
                                                            <i class="fa fa-plus"></i>
                                                            Select files
                                                            </file-upload>
                                                            <a :href=" form.thumbnail" target="_blank" type="button" class="btn btn-info ml-1">
                                                                <i class="fa fa-file" aria-hidden="true"></i>
                                                                Current file
                                                            </a>
                                                            <button type="button" class="btn btn-danger ml-1"  v-if="!$refs.thumb || $refs.thumb.active" @click.prevent="$refs.thumb.active = false">
                                                            <i class="fa fa-stop" aria-hidden="true"></i>
                                                            Stop Upload
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 mb-2">
                                                <div class="card" style="height: 25vh">
                                                    <div v-if="files.length">
                                                        <div class="progress" style="height: 7px;">
                                                            <div class="progress-bar progress-bar-striped" :class="{'progress-bar-animated': files[0].active, 'w-50 bg-current' : files[0].active, 'w-100 bg-current': files[0].success, 'w-100 bg-danger' : files[0].error}" role="progressbar" :style="{width: files[0].progress + '%'}"></div>
                                                        </div>
                                                    </div>
                                                    <div class="text-center py-5">
                                                        <ul v-if="files.length">
                                                            <li>
                                                                <span>{{files[0].name}} - {{files[0].size | sizeFormat}}</span> -
                                                                <!-- <span>{{$formatSize(files[0].size)}}</span> - -->
                                                                <span class="badge badge-danger text-capitalize" v-if="files[0].error">{{files[0].error}}</span>
                                                                <span class="badge badge-success" v-else-if="files[0].success"><i class="fas fa-check"></i></span>
                                                                <div v-else-if="files[0].active" class="spinner-border spinner-border-sm text-warning" role="status">
                                                                    <span class="sr-only">Loading...</span>
                                                                </div>
                                                                <span v-else></span>
                                                            </li>
                                                        </ul>
                                                        <h4 v-else>Input your file !</h4>
                                                        <div class="m-auto">
                                                            <file-upload
                                                            accept="audio/*"
                                                            class="btn btn-current mr-1"
                                                            @input="updateFile"
                                                            @input-file="inputFile"
                                                            @input-filter="fileFilter"
                                                            :custom-action="fileAction"
                                                            ref="upload">
                                                            <i class="fa fa-plus"></i>
                                                            Select files
                                                            </file-upload>
                                                            <a :href=" form.path" target="_blank" type="button" class="btn btn-info ml-1">
                                                                <i class="fa fa-file" aria-hidden="true"></i>
                                                                Current file
                                                            </a>
                                                            <button type="button" class="btn btn-danger ml-1"  v-if="!$refs.upload || $refs.upload.active" @click.prevent="$refs.upload.active = false">
                                                            <i class="fa fa-stop" aria-hidden="true"></i>
                                                            Stop Upload
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 mt-4">
                                        <div class="row">
                                            <div class="form-group col-md-12">
                                                <label for="">Title</label>
                                                <input type="text" class="form-control" required v-model="form.title" placeholder="Insert Title">
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="">Term</label>
                                                <select v-model="form.cycle" class="form-control" required @change="getSyllabus()">
                                                    <option value="" selected disabled>-- Select Term --</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                </select>
                                            </div>
                                            <div class="col-6 form-group">
                                                <label for="">Unit</label>
                                                <input v-if="unit.length == 0" type="text" class="form-control" placeholder="-- Select Unit --" disabled>
                                                <select v-else required v-model="form.unit_id" class="form-control text-grey-900 font-xsss fw-600">
                                                    <option value="" disabled selected>-- Select Unit --</option>
                                                    <option v-for="(item, index) in unit" :key="index" :value="item.id">
                                                        {{item.content}}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="form-group col-md-12">
                                                <label for="">Description</label>
                                                <textarea v-model="form.description" rows="4" class="form-control" placeholder="Insert Description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 text-right">
                                        <router-link :to="{name: 'FilesCoursesAdmin', params: {idCourse: paramsId}, hash: '#podcast'}" class="btn btn-outline-warning mr-4" value="Submit">Cancel</router-link>
                                        <button type="submit" class="btn btn-current">
                                        Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div v-else class="col-12 text-center py-3">
                    <img :src="'/images/empty.png'" alt="" width="300">
                    <h1 class="text-muted">Data don't exist</h1>
                </div>
            </div>
            <div v-else class="row">
                <div class="col-12 text-center py-3">
                    <div class="my-3">
                        <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                        <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    </div>
            </div>
        </div>
    </div> 
</template>
<script>
import axios from 'axios'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.withCredentials = true
import FileUpload from 'vue-upload-component'
export default {
    components: {
        FileUpload,
    },
    data(){
        return {
            media: process.env.VUE_APP_URL_CLOUD,
            isLoad: true,
            thumbs: [],
            files: [],
            syllabus: [],
            unit: [],
            paramsId: this.$route.params.idCourse,
            idFile: this.$route.params.idFile,
            form : {
                id: '',
                thumbnail: '',
                path: '',
                title: '',
                description: '',
                unit_id: '',
                syllabus_id: '',
                cycle: '',
            }
        }
    },
    filters:{
        sizeFormat: function (size) {
            if (size > 1024 * 1024 * 1024 * 1024) {
                return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
            } else if (size > 1024 * 1024 * 1024) {
                return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
            } else if (size > 1024 * 1024) {
                return (size / 1024 / 1024).toFixed(2) + ' MB'
            } else if (size > 1024) {
                return (size / 1024).toFixed(2) + ' KB'
            }
            return size.toString() + ' B'
        }
    },
    created(){
        this.getMaterials()
    },
    methods: {
        async getMaterials(){
            await axios.get(`${process.env.VUE_APP_URL_API}/teacher/material/detail?slug_course=${this.paramsId}&id=${this.idFile}`, {
                headers: {
                Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.form = res.data
                this.form.syllabus_id = res.data.syllabus_id
                this.form.unit_id = res.data.unit_id
                var list = []
                list.push(res.data.unit)
                this.unit = list
                this.isLoad = false
            }).catch((err) => console.error(err.response))
        },
        async getSyllabus(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/syllabus?slug=${this.paramsId}&cycle=${this.form.cycle}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.syllabus = res.data.data
                this.form.syllabus_id = this.syllabus.id
                this.unit = this.syllabus.point
            })
        },
        updateThumb(value) {
            this.thumbs = value
        },
        updateFile(value) {
            this.files = value
        },
        async thumbAction(file) {
            return this.postThumb(file)
        },
        async fileAction(file) {
            return this.postFile(file)
        },
        async inputThumb(newFile, oldFile) {
            // if (newFile && oldFile) {
            //     if (newFile.active !== oldFile.active) {
            //         if (newFile.size > 2000 * 1024) {
            //             newFile = this.$refs.thumb.update(newFile, {error: 'size'})
            //         }
            //     }
            // }
            if (!newFile && oldFile) {
                if (oldFile.success && oldFile.response.path) {
                    // this.deleteFile(oldFile)
                }
            }
            if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
                if (!this.$refs.thumb.active) {
                    this.$refs.thumb.active = true
                }
            }
        },
        async inputFile(newFile, oldFile) {
            if (!newFile && oldFile) {
                if (oldFile.success && oldFile.response.path) {
                    // this.deleteFile(oldFile)
                }
            }
            if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
                if (!this.$refs.upload.active) {
                    this.$refs.upload.active = true
                }
            }
        },
        thumbFilter(newFile, oldFile, prevent) {
            if (newFile && !oldFile) {
                if (!/\.(png|jpg)$/i.test(newFile.name)) {
                    return prevent()
                }
            }
        },
        fileFilter(newFile, oldFile, prevent) {
            if (newFile && !oldFile) {
                if (!/\.(mp3)$/i.test(newFile.name)) {
                    return prevent()
                }
            }
        },
        async postFile(file){
            let data = new FormData();
            data.append('dir', 'materials');
            data.append('attachment', file.file);
            data.append('_token', 'elingwaysecretkey');
            await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/store-cloud`, data, {withCredentials: false}, {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                file.response = res.data
                this.form.path = res.data.path
                return res.data
            })
        },
        async postThumb(file){
            let data = new FormData();
            data.append('dir', 'materials');
            data.append('attachment', file.file);
            data.append('_token', 'elingwaysecretkey');
            await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/store-cloud`, data, {withCredentials: false}, {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                file.response = res.data
                this.form.thumbnail = res.data.path
                return res.data
            })
        },
        async deleteFile(file){
            let data = new FormData();
            data.append('path', file.response.path);
            data.append('_token', 'elingwaysecretkey');
            await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/delete`, data, {withCredentials: false}, {
                headers: {
                    'Accept': 'application/json',
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                return res.data
            })
        },
        async patchMaterials() {
            var data = {
                _method: 'patch',
                id: this.form.id,
                thumbnail: this.form.thumbnail,
                path: this.form.path,
                title: this.form.title,
                description: this.form.description,
                course_id: this.form.course_id,
                syllabus_id: this.form.syllabus_id,
                unit_id: this.form.unit_id,
                cycle: this.form.cycle,
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/teacher/material`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.$swal({
                    toast: true,
                    title: 'Update Materials!',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.$router.push({ name: 'FilesCoursesAdmin', params: { idCourse: this.paramsId }, hash: '#podcast' })
            }).catch(err => {
                if (err.response.status == 422){
                    this.validationErrors = Object.values(err.response.data.errors);
                    this.$swal({
                        toast: true,
                        title: 'Update Material!',
                        text: this.validationErrors[0][0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        }
    }
}
</script>